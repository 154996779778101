import PageLayout from '@global/page-layout';
import React from 'react';
import CTA from '@containers/CTA';
import Industries from '@containers/Industries';
import { useStaticQuery, graphql } from 'gatsby';
import { popup } from '@src/global/utils/typeformPopup';
import CTA3 from '@containers/CTA3';
import { TWButton, TWBadge } from '@styled/components';
import IntegrationsPreview from '@containers/IntegrationsPreview';

const Integrations = () => {
  const data = useStaticQuery(graphql`
    query allIntegrationsSectionJson {
      integrationsSectionJson {
        title
        subTitle
        highlightedIntegrations {
          link
          title
          image
          description
          tag
          type
        }
      }
    }
  `);

  const {
    title,
    subTitle,
    highlightedIntegrations,
  } = data.integrationsSectionJson;

  const accountingIntegrations = highlightedIntegrations.filter(
    (integration) => integration.type === 'Accounting'
  );
  const paymentsIntegrations = highlightedIntegrations.filter(
    (integration) => integration.type === 'Payment'
  );

  return (
    <PageLayout title="Integrations">
      <div className="bg-gray-200 pt-6">
        <section className="text-gray-700 body-font">
          <div className="container px-5 py-24 mx-auto ">
            <div className="flex flex-wrap w-full mb-20">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                  {title}
                </h1>
                <div className="h-1 w-20 bg-green-500 rounded" />
              </div>
              <p className="lg:w-1/2 w-full leading-relaxed">{subTitle}</p>
            </div>
          </div>
        </section>
        <section className="text-gray-700 body-font bg-white">
          <div className="container px-5 pb-12 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="container px-5 py-6 mx-auto ">
                <div className="text-center py-12">
                  <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                    Accounting Integrations
                  </h1>
                </div>
                <div className="flex flex-wrap -m-4">
                  {accountingIntegrations.map((integration, key) => (
                    <IntegrationsPreview integration={integration} key={key} />
                  ))}
                </div>
                {/* this is where */}
              </div>

              <div className="container px-5 py-6 mx-auto">
                <div className="text-center py-12">
                  <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                    Payments Integrations
                  </h1>
                </div>

                <div className="flex flex-wrap -m-4">
                  {paymentsIntegrations.map((integration, key) => (
                    <IntegrationsPreview integration={integration} key={key} />
                  ))}
                </div>
                {/* this is where */}
              </div>
            </div>
          </div>
        </section>
        <CTA3 gray onPress={() => popup()} />
      </div>
    </PageLayout>
  );
};

export default Integrations;

// <div className="-my-8">
//   {highlightedIntegrations.map((integration, key) => (
//     <div
//       className="flex flex-wrap md:flex-no-wrap items-center"
//       key={key}
//     >
//       <div className="md:w-64 w-full col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
//         <img
//           className="h-12"
//           src={integration.image}
//           alt={integration.title}
//         />
//       </div>
//       <div className="md:flex-grow py-8 px-8">
//         <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
//           {integration.title}
//           <span>
//             {integration.tag ? (
//               <TWBadge
//                 className="inline-flex items-center px-3 ml-2 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"
//                 title={integration.tag}
//               />
//             ) : null}
//           </span>
//         </h2>

//         <p className="leading-relaxed text-lg">
//           {integration.description}
//         </p>
//         <a
//           href={integration.link}
//           target="_blank"
//           rel="noreferrer"
//           className="text-green-600 text-base inline-flex items-center mt-4"
//         >
//           Learn More about {integration.title}
//           <svg
//             className="w-4 h-4 ml-2"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//             strokeWidth={2}
//             fill="none"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           >
//             <path d="M5 12h14" />
//             <path d="M12 5l7 7-7 7" />
//           </svg>
//         </a>
//       </div>
//     </div>
//   ))}
// </div>;

import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/tailwind.css';
import { Link } from 'gatsby';
import { TWButton, TWBadge } from '@styled/components';

const isAvailable = (status) => status === 'Available';
const IntegrationsPreview = ({ integration }) => (
  <div className="xl:w-1/4 md:w-1/2 p-4">
    <div className="bg-gray-200 p-6 rounded-lg">
      <img
        className="h-12 mb-2"
        src={integration.image}
        alt={integration.title}
      />
      {/* <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                          {integration.title}
                        </h2> */}
      <span>
        {integration.tag ? (
          <TWBadge
            className={`${
              isAvailable(integration.tag)
                ? 'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-green-500 text-gray-200'
                : 'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800'
            }`}
            title={integration.tag}
          />
        ) : null}
      </span>
      <p className="leading-relaxed text-base mt-2">
        {integration.description}
      </p>
      <a
        href={integration.link}
        target="_blank"
        rel="noreferrer"
        className="text-green-600 text-base inline-flex items-center mt-4"
      >
        Learn about {integration.title}
        <svg
          className="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14" />
          <path d="M12 5l7 7-7 7" />
        </svg>
      </a>
    </div>
  </div>
);

IntegrationsPreview.propTypes = {
  integration: PropTypes.object.isRequired,
};

export default IntegrationsPreview;
